import { React } from "react";
import Projects from "../Projects/Projects";
import "./LandingPage.css";

const LandingPage = () => {
  return (
    <div className="landingPageFlex">
      <div>
        {" "}
        <p id="bidsite">
          <a href="https://bidsite.net/">Bidsite (Full Stack) - PHPRunner</a>
        </p>
        <a href="http://bidsite.net">
          <img
            className="landingPreviewPic"
            alt="BidSite"
            src={process.env.PUBLIC_URL + "/images/bidsite.jpg"}
          ></img>
        </a>
        <div id="description">
          <h4> PROJECT DESCRIPTION</h4> Bidsite.net (above) was created prior to
          my learning "How to Code". The other projects contained in this
          portflio are a compilation of projects from the time I became a
          professional developer. I've worked with various development
          environments and frameworks. And the "Journey" continues.
        </div>
        <div id="techUsedTitle">
          <h4>Technology Used: </h4>
          HTML/CSS, JavaScript, React, PostgreSQL, Typescript, PHPRunner
          (backend/prototyping), EMS SQL Manager, SQLite3, SQLite3 Studio,
          Wordpress, Node.js, Express.js, Next.js, Firebase, Firebase Functions,
          Firebase Admin, Cors, clsx: class Module Name Management,
          Next-Connect: Middleware, Formidable-erverless: form parsing that wont
          destroy image files, Nodemailer: contact form emailing solution,
          React-scroll: Navigation, React-markdown: formatting customer facing
          data, React-router (5/6), Framer-motion: animations, Firebase hosting,
          Heroku hosting, Namecheap hosting, Figma, Github , Notepadd ++, VS
          code, Excel, Gimp, Filezilla
          <br />
        </div>
      </div>

      <div className="projectsTechColumn">
        <div id="projectsMenu">
          <Projects />
        </div>
        <div id="comingSoon">
          COMING SOON
          <div />
          <div>
            <video id="video" autoPlay muted>
              <source
                src={process.env.PUBLIC_URL + "/images/raceVideo.mp4"}
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;

// https://securecannabisalliance.com/
